export default function ({ $axios, redirect, app, $auth }) {
  $axios.onRequest(() => {
    // const token = app.$cookiz.get('AccessToken')
    // if(token) $axios.setToken(token, 'Bearer')
  })

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      // do something
    } else if (code === 401) {
      if (error.response.data.message === 'Unauthenticated.') {
        redirect('/login')
      }
    }
  })
}
